import { Link } from "gatsby";
import React from "react";

// Components
import { Button } from "@components";

// markup
const NotFoundPage = () => {
  return (
    <div className="bg-gradient-to-r from-secondary to-primary">
      <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-8">
            <h1 className="text-5xl sm:text-7xl lg:text-9xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
              404
            </h1>
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-medium py-8">oops! Page not found</h1>
            <p className="text-xl sm:text-2xl pb-8 px-12 font-medium">
              Oops! The page you are looking for does not exist. It might have
              been moved or deleted.
            </p>

            <div className="flex flex-col sm:flex-row justify-center gap-x-7 gap-y-2.5">
              <Link to="/">
                <Button label={"Home"} color={"blue"} size={"md"} />
              </Link>
              <Link to="/contact-us">
                <Button
                  label={"Contact Us"}
                  color={"white"}
                  size={"md"}
                  border={"border"}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
